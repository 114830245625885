.bazis-slider {
    &__wrapper {
        overflow: hidden;
    }

    &__content {
        display: flex;
        align-items: stretch;
        will-change: transform;
        // transition: margin-left 300ms;
        transition: transform 300ms;

        > * {
            flex-shrink: 0;
        }
    }
    &__controls {
        margin-top: 32px;
        text-align: center;

        bazis-button {
            margin: 0 4px;
        }
    }
}
