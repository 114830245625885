.bh-no-padding {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

@for $i from 1 through 8 {
    .bh-padding-#{$i}x {
        --bazis-padding: calc(var(--bazis-multiple-px) * #{$i});

        --padding-start: var(--bazis-padding, 12px);
        --padding-end: var(--bazis-padding, 12px);
        --padding-top: var(--bazis-padding, 12px);
        --padding-bottom: var(--bazis-padding, 12px);
        padding-left: var(--bazis-padding, 12px) !important;
        padding-right: var(--bazis-padding, 12px) !important;
        padding-top: var(--bazis-padding, 12px) !important;
        padding-bottom: var(--bazis-padding, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-padding-#{$i}x {
            padding-left: unset;
            padding-right: unset;
            -webkit-padding-start: var(--bazis-padding, 12px) !important;
            padding-inline-start: var(--bazis-padding, 12px) !important;
            -webkit-padding-end: var(--bazis-padding, 12px) !important;
            padding-inline-end: var(--bazis-padding, 12px) !important;
        }
    }

    .bh-padding-top-#{$i}x {
        --bazis-padding: calc(var(--bazis-multiple-px) * #{$i});
        --padding-top: var(--bazis-padding, 12px);
        padding-top: var(--bazis-padding, 12px) !important;
    }
    .bh-padding-start-#{$i}x {
        --bazis-padding: calc(var(--bazis-multiple-px) * #{$i});
        --padding-start: var(--bazis-padding, 12px);
        padding-left: var(--bazis-padding, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-padding-start-#{$i}x {
            padding-left: unset;
            -webkit-padding-start: var(--bazis-padding, 12px) !important;
            padding-inline-start: var(--bazis-padding, 12px) !important;
        }
    }

    .bh-padding-end-#{$i}x {
        --bazis-padding: calc(var(--bazis-multiple-px) * #{$i});
        --padding-end: var(--bazis-padding, 12px);
        padding-right: var(--bazis-padding, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-padding-end-#{$i}x {
            padding-right: unset;
            -webkit-padding-end: var(--bazis-padding, 12px) !important;
            padding-inline-end: var(--bazis-padding, 12px) !important;
        }
    }

    .bh-padding-bottom-#{$i}x {
        --bazis-padding: calc(var(--bazis-multiple-px) * #{$i});
        --padding-bottom: var(--bazis-padding, 12px);
        padding-bottom: var(--bazis-padding, 12px) !important;
    }
    .bh-padding-vertical-#{$i}x {
        --bazis-padding: calc(var(--bazis-multiple-px) * #{$i});
        --padding-top: var(--bazis-padding, 12px);
        --padding-bottom: var(--bazis-padding, 12px);
        padding-top: var(--bazis-padding, 12px) !important;
        padding-bottom: var(--bazis-padding, 12px) !important;
    }
    .bh-padding-horizontal-#{$i}x {
        --bazis-padding: calc(var(--bazis-multiple-px) * #{$i});
        --padding-start: var(--bazis-padding, 12px);
        --padding-end: var(--bazis-padding, 12px);
        padding-left: var(--bazis-padding, 12px) !important;
        padding-right: var(--bazis-padding, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-padding-horizontal-#{$i}x {
            padding-left: unset;
            padding-right: unset;
            -webkit-padding-start: var(--bazis-padding, 12px) !important;
            padding-inline-start: var(--bazis-padding, 12px) !important;
            -webkit-padding-end: var(--bazis-padding, 12px) !important;
            padding-inline-end: var(--bazis-padding, 12px) !important;
        }
    }
}

/// оставлено для обратной совместимости с SW
.bh-padding {
    --bazis-padding: calc(var(--bazis-multiple-px) * var(--bazis-coef));

    --padding-start: var(--bazis-padding, 12px);
    --padding-end: var(--bazis-padding, 12px);
    --padding-top: var(--bazis-padding, 12px);
    --padding-bottom: var(--bazis-padding, 12px);
    padding-left: var(--bazis-padding, 12px) !important;
    padding-right: var(--bazis-padding, 12px) !important;
    padding-top: var(--bazis-padding, 12px) !important;
    padding-bottom: var(--bazis-padding, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .bh-padding {
        padding-left: unset;
        padding-right: unset;
        -webkit-padding-start: var(--bazis-padding, 12px) !important;
        padding-inline-start: var(--bazis-padding, 12px) !important;
        -webkit-padding-end: var(--bazis-padding, 12px) !important;
        padding-inline-end: var(--bazis-padding, 12px) !important;
    }
}

.bh-padding-top {
    --bazis-padding: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --padding-top: var(--bazis-padding, 12px);
    padding-top: var(--bazis-padding, 12px) !important;
}
.bh-padding-start {
    --bazis-padding: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --padding-start: var(--bazis-padding, 12px);
    padding-left: var(--bazis-padding, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .bh-padding-start {
        padding-left: unset;
        -webkit-padding-start: var(--bazis-padding, 12px) !important;
        padding-inline-start: var(--bazis-padding, 12px) !important;
    }
}

.bh-padding-end {
    --bazis-padding: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --padding-end: var(--bazis-padding, 12px);
    padding-right: var(--bazis-padding, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .bh-padding-end {
        padding-right: unset;
        -webkit-padding-end: var(--bazis-padding, 12px) !important;
        padding-inline-end: var(--bazis-padding, 12px) !important;
    }
}

.bh-padding-bottom {
    --bazis-padding: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --padding-bottom: var(--bazis-padding, 12px);
    padding-bottom: var(--bazis-padding, 12px) !important;
}
.bh-padding-vertical {
    --bazis-padding: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --padding-top: var(--bazis-padding, 12px);
    --padding-bottom: var(--bazis-padding, 12px);
    padding-top: var(--bazis-padding, 12px) !important;
    padding-bottom: var(--bazis-padding, 12px) !important;
}
.bh-padding-horizontal {
    --bazis-padding: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --padding-start: var(--bazis-padding, 12px);
    --padding-end: var(--bazis-padding, 12px);
    padding-left: var(--bazis-padding, 12px) !important;
    padding-right: var(--bazis-padding, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .bh-padding-horizontal {
        padding-left: unset;
        padding-right: unset;
        -webkit-padding-start: var(--bazis-padding, 12px) !important;
        padding-inline-start: var(--bazis-padding, 12px) !important;
        -webkit-padding-end: var(--bazis-padding, 12px) !important;
        padding-inline-end: var(--bazis-padding, 12px) !important;
    }
}

.bh-no-border {
    border: none !important;
}
