// https://web-standards.ru/articles/native-and-custom-select/
// Both native and custom selects must have the same width/height.
.bazis-select-native,
.bazis-select {
    position: relative;
    width: 100%;
    height: 100%;
}

// Make sure the custom select does not mess with the layout
.bazis-select {
    --padding-start: initial;
    --padding-end: initial;
    --border-radius: initial;
    --background: initial;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    padding-left: var(--padding-start);
    padding-right: var(--padding-end);
    border-radius: var(--border-radius);
}

// This media query detects devices where the primary
// input mechanism can hover over elements. (e.g. computers with a mouse)
// @media (hover: hover) {
// Since we are using a mouse, it's safe to show the custom select.
.bazis-select {
    display: block;
}

// In a computer using keyboard? Then let's hide back the custom select
// while the native one is focused:
.bazis-select-native:focus + .bazis-select {
    display: none;
}
// }

//
// Rest of the styles to create the custom select.
// Just make sure the native and the custom have a similar "box" (the trigger).
//

.bazis-select-native {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    outline: 0;
    margin-left: 24px; // невидимый запас под возможную иконку
    // TODO: подумать, как лучше оформить, так как иконка может быть не всегда

    app-select-filter & {
        margin-left: 0;
    }
}

.bazis-select__trigger {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--background);
    cursor: pointer;

    span {
        @include text-ellipsis;
    }

    & + .bazis-control__field {
        --padding-field-start: 0;
        --padding-field-end: 0;
        position: absolute;
        top: 0;
        opacity: 0;
        background: var(--bazis-background);

        &--focused {
            opacity: 1;
        }
    }
}
