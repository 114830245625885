.bazis-empty {
    @include props-padding;
    @include props-colors;
    @include props-font;
    @include padding;
    --margin-top: 0;
    --margin-bottom: 0;

    margin-top: var(--margin-top);
    margin-bottom: var(--margin-bottom);
    background: var(--background);

    p {
        @include font();
        color: var(--color);
    }

    text-align: center;
}

// implemented
.bazis-empty {
    --margin-top: 120px;
    --font-size: 13px;
    --line-height: 20px;
    --color: var(--bazis-text-color-secondary);
    max-width: 548px;
    margin-left: auto;
    margin-right: auto;

    h5 {
        margin-bottom: 8px;
    }

    bazis-button {
        margin-top: 24px;
    }
}
