.bh-no-margin {
    --margin-start: 0 !important;
    --margin-end: 0 !important;
    --margin-top: 0 !important;
    --margin-bottom: 0 !important;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}

@for $i from 1 through 8 {
    .bh-margin-#{$i}x {
        --bazis-margin: calc(var(--bazis-multiple-px) * #{$i});
        --margin-start: var(--bazis-margin, 12px);
        --margin-end: var(--bazis-margin, 12px);
        --margin-top: var(--bazis-margin, 12px);
        --margin-bottom: var(--bazis-margin, 12px);
        margin-left: var(--bazis-margin, 12px) !important;
        margin-right: var(--bazis-margin, 12px) !important;
        margin-top: var(--bazis-margin, 12px) !important;
        margin-bottom: var(--bazis-margin, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-margin-#{$i}x {
            margin-left: unset !important;
            margin-right: unset !important;
            -webkit-margin-start: var(--bazis-margin, 12px) !important;
            margin-inline-start: var(--bazis-margin, 12px) !important;
            -webkit-margin-end: var(--bazis-margin, 12px) !important;
            margin-inline-end: var(--bazis-margin, 12px) !important;
        }
    }

    .bh-margin-top-#{$i}x {
        --bazis-margin: calc(var(--bazis-multiple-px) * #{$i});
        --margin-top: var(--bazis-margin, 12px) !important;
        margin-top: var(--bazis-margin, 12px) !important;
    }
    .bh-margin-start-#{$i}x {
        --bazis-margin: calc(var(--bazis-multiple-px) * #{$i});
        --margin-start: var(--bazis-margin, 12px);
        margin-left: var(--bazis-margin, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-margin-start-#{$i}x {
            margin-left: unset !important;
            -webkit-margin-start: var(--bazis-margin, 12px) !important;
            margin-inline-start: var(--bazis-margin, 12px) !important;
        }
    }

    .bh-margin-end-#{$i}x {
        --bazis-margin: calc(var(--bazis-multiple-px) * #{$i});
        --margin-end: var(--bazis-margin, 12px);
        margin-right: var(--bazis-margin, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-margin-end-#{$i}x {
            margin-right: unset !important;
            -webkit-margin-end: var(--bazis-margin, 12px) !important;
            margin-inline-end: var(--bazis-margin, 12px) !important;
        }
    }

    .bh-margin-bottom-#{$i}x {
        --bazis-margin: calc(var(--bazis-multiple-px) * #{$i});
        --margin-bottom: var(--bazis-margin, 12px);
        margin-bottom: var(--bazis-margin, 12px) !important;
    }
    .bh-margin-vertical-#{$i}x {
        --bazis-margin: calc(var(--bazis-multiple-px) * #{$i});
        --margin-top: var(--bazis-margin, 12px);
        --margin-bottom: var(--bazis-margin, 12px);
        margin-top: var(--bazis-margin, 12px) !important;
        margin-bottom: var(--bazis-margin, 12px) !important;
    }
    .bh-margin-horizontal-#{$i}x {
        --bazis-margin: calc(var(--bazis-multiple-px) * #{$i});
        --margin-start: var(--bazis-margin, 12px);
        --margin-end: var(--bazis-margin, 12px);
        margin-left: var(--bazis-margin, 12px) !important;
        margin-right: var(--bazis-margin, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-margin-horizontal-#{$i}x {
            margin-left: unset !important;
            margin-right: unset !important;
            -webkit-margin-start: var(--bazis-margin, 12px) !important;
            margin-inline-start: var(--bazis-margin, 12px) !important;
            -webkit-margin-end: var(--bazis-margin, 12px) !important;
            margin-inline-end: var(--bazis-margin, 12px) !important;
        }
    }
}

/// оставлено для обратной совместимости с SW
.bh-margin {
    --bazis-margin: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --margin-start: var(--bazis-margin, 12px);
    --margin-end: var(--bazis-margin, 12px);
    --margin-top: var(--bazis-margin, 12px);
    --margin-bottom: var(--bazis-margin, 12px);
    margin-left: var(--bazis-margin, 12px) !important;
    margin-right: var(--bazis-margin, 12px) !important;
    margin-top: var(--bazis-margin, 12px) !important;
    margin-bottom: var(--bazis-margin, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .bh-margin {
        margin-left: unset !important;
        margin-right: unset !important;
        -webkit-margin-start: var(--bazis-margin, 12px) !important;
        margin-inline-start: var(--bazis-margin, 12px) !important;
        -webkit-margin-end: var(--bazis-margin, 12px) !important;
        margin-inline-end: var(--bazis-margin, 12px) !important;
    }
}

.bh-margin-top {
    --bazis-margin: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --margin-top: var(--bazis-margin, 12px) !important;
    margin-top: var(--bazis-margin, 12px) !important;
}
.bh-margin-start {
    --bazis-margin: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --margin-start: var(--bazis-margin, 12px);
    margin-left: var(--bazis-margin, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .bh-margin-start {
        margin-left: unset !important;
        -webkit-margin-start: var(--bazis-margin, 12px) !important;
        margin-inline-start: var(--bazis-margin, 12px) !important;
    }
}

.bh-margin-end {
    --bazis-margin: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --margin-end: var(--bazis-margin, 12px);
    margin-right: var(--bazis-margin, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .bh-margin-end {
        margin-right: unset !important;
        -webkit-margin-end: var(--bazis-margin, 12px) !important;
        margin-inline-end: var(--bazis-margin, 12px) !important;
    }
}

.bh-margin-bottom {
    --bazis-margin: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --margin-bottom: var(--bazis-margin, 12px);
    margin-bottom: var(--bazis-margin, 12px) !important;
}
.bh-margin-vertical {
    --bazis-margin: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --margin-top: var(--bazis-margin, 12px);
    --margin-bottom: var(--bazis-margin, 12px);
    margin-top: var(--bazis-margin, 12px) !important;
    margin-bottom: var(--bazis-margin, 12px) !important;
}
.bh-margin-horizontal {
    --bazis-margin: calc(var(--bazis-multiple-px) * var(--bazis-coef));
    --margin-start: var(--bazis-margin, 12px);
    --margin-end: var(--bazis-margin, 12px);
    margin-left: var(--bazis-margin, 12px) !important;
    margin-right: var(--bazis-margin, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .bh-margin-horizontal {
        margin-left: unset !important;
        margin-right: unset !important;
        -webkit-margin-start: var(--bazis-margin, 12px) !important;
        margin-inline-start: var(--bazis-margin, 12px) !important;
        -webkit-margin-end: var(--bazis-margin, 12px) !important;
        margin-inline-end: var(--bazis-margin, 12px) !important;
    }
}
